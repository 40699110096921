@import 'src/styles/variables';

.search {
  position: relative;
  display: flex;
  align-items: center;

  &.disabled {
    pointer-events: none;

    i,
    span {
      color: var(--color-lower-middle-grey);
    }
  }

  .filter-block {
    padding: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 8px;
    transition: 0.3s background-color;

    &.with-filter-data {
      background: var(--color-light-blue);
      border-radius: 8px 0 0 8px;

      &:hover {
        background: var(--color-light-blue-hover);
      }

      i,
      span {
        color: var(--color-blue);
      }
    }

    &:hover,
    &.dropdown-open {
      background: var(--color-light-grey);
    }

    i {
      margin-right: 8px;
    }
  }

  .filter-clear {
    cursor: pointer;
    background: var(--color-light-blue);
    width: 32px;
    height: 32px;
    border-left: 1px solid var(--color-light-blue-hover);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 8px 8px 0;

    &:hover {
      background: var(--color-light-blue-hover);
    }
  }

  .search-input-wrapper {
    position: absolute;
    top: 40px;
    z-index: 5;
    background: rgb(255, 255, 255);
    padding: 8px;
    min-height: 48px;
    border-radius: 8px;
    box-shadow:
      0 8px 22px 0 rgba(94, 103, 116, 0.1),
      0 2px 6px 0 rgba(94, 103, 116, 0.1);
  }

  .search-input {
    position: absolute;
    width: 362px;
  }

  .input-search {
    width: 362px;
  }

  .list-items {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 362px;
  }

  .list-item {
    width: 362px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 12px;

    &:hover {
      background: var(--color-white-grey);
    }

    &__image {
      height: 26px;
      width: 26px;
    }

    &__title {
      padding-top: 6px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.dropzone {
  height: 145px;
  width: 100%;
  border-radius: 8px;
  border: 1px dashed var(--color-divider-grey);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.disabled {
    background: var(--color-white-grey);
    pointer-events: none;

    .dropzone__icon {
      i {
        color: var(--color-extra-grey);
      }
    }

    .dropzone__title {
      p, span {
        color: var(--color-extra-grey);
      }
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    &--accept {
      span {
        color: var(--color-blue);
      }
    }

    &--reject {
      span {
        color: var(--color-red);
      }
    }

    p {
      span {
        margin-left: 5px;
      }
    }
  }

  &__description {
    margin-top: 12px;
    font-size: 10px;
    line-height: 13px;
  }
}

@import 'src/styles/typography';

.products {
  position: relative;

  &__header {
    &-wrapper {
      padding-bottom: 8px;
    }

    display: flex;
    justify-content: space-between;
  }

  &__content {
    .image-cell-wrapper {
      margin: -4px;
    }

    //.table-tr {
    //  & > div &:nth-child(4) {
    //    color: red;
    //  }
    //}
  }

  &__footer {
    padding: 36px 24px 28px;
    display: flex;
    align-items: center;

    .limit {
      display: flex;
      align-items: center;
      margin-left: auto;
    }
  }
}

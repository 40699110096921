.orders {
  &__header {
    display: flex;
    justify-content: space-between;

    .filters {
      display: flex;
      align-items: center;
    }
  }

  &__content {
    padding: 0 24px;
  }

  &__footer {
    padding: 28px 24px;
    display: flex;
    align-items: center;

    .limit {
      display: flex;
      align-items: center;
      margin-left: auto;
    }
  }
}

@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?dxwuh5');
  src:  url('fonts/icomoon.eot?dxwuh5#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?dxwuh5') format('truetype'),
    url('fonts/icomoon.woff?dxwuh5') format('woff'),
    url('fonts/icomoon.svg?dxwuh5#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-partnership:before {
  content: "\e925";
}
.icon-location:before {
  content: "\e927";
}
.icon-pin:before {
  content: "\e926";
}
.icon-category:before {
  content: "\e922";
}
.icon-delivery:before {
  content: "\e923";
}
.icon-qr-code:before {
  content: "\e924";
}
.icon-select_file:before {
  content: "\e91f";
}
.icon-msg_send:before {
  content: "\e91c";
}
.icon-msg_read:before {
  content: "\e920";
}
.icon-quantity:before {
  content: "\e921";
}
.icon-delete:before {
  content: "\e91b";
}
.icon-send:before {
  content: "\e91e";
}
.icon-heart:before {
  content: "\e91a";
}
.icon-star:before {
  content: "\e919";
}
.icon-share:before {
  content: "\e938";
}
.icon-duplicate:before {
  content: "\e914";
}
.icon-edit:before {
  content: "\e915";
}
.icon-remove:before {
  content: "\e916";
}
.icon-start:before {
  content: "\e917";
}
.icon-more:before {
  content: "\e913";
}
.icon-close:before {
  content: "\e91d";
}
.icon-plus:before {
  content: "\e902";
}
.icon-item:before {
  content: "\e904";
}
.icon-message:before {
  content: "\e905";
}
.icon-document:before {
  content: "\e906";
}
.icon-like:before {
  content: "\e907";
}
.icon-search-light:before {
  content: "\e908";
}
.icon-statistics:before {
  content: "\e909";
}
.icon-add:before {
  content: "\e90a";
}
.icon-arrow-down:before {
  content: "\e90b";
}
.icon-arrow-up:before {
  content: "\e903";
}
.icon-check:before {
  content: "\e90c";
}
.icon-calendar:before {
  content: "\e90d";
}
.icon-exit:before {
  content: "\e90e";
}
.icon-input-clear .path1:before {
  content: "\e90f";
  color: rgb(240, 241, 243);
}
.icon-input-clear .path2:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(128, 138, 152);
}
.icon-search:before {
  content: "\e911";
}
.icon-status:before {
  content: "\e912";
}
.icon-image-square:before {
  content: "\e901";
}
.icon-question-circle:before {
  content: "\e900";
}
.icon-arrow-right:before {
  content: "\ea50";
}
.icon-arrow-left:before {
  content: "\ea51";
}
.icon-settings:before {
  content: "\e918";
}

@import '../../../../styles/typography';

.message_block {
  top: 0;
  bottom: -10px;
  flex: 1;

  .date-header-wrapper {
    text-align: center;
    margin: 20px 0 10px;
  }

  .date-header {
    padding: 4px 8px;
    border-radius: 16px;
    background: var(--color-light-grey);
  }
}

.message {
  column-gap: 8px;
  display: flex;
  align-items: center;
  margin: 8px 0 0px 16px;
  box-sizing: border-box;
  max-width: 70%;

  &__block {
    padding: 12px 16px;
    background: var(--color-light-grey);
    border-radius: 22px 22px 22px 0;
  }
}

.message_image {
  .message_text {
    padding: 12px 16px;
    background: '';
  }
}

.text_message {
  word-break: break-word;
  white-space: pre-wrap;
}

.self_message {
  margin: 0 16px 0 0;

  .message_text {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .message__block {
    border-radius: 22px 22px 0 22px;
    background: var(--color-blue);
  }
}

@import '../../../styles/typography';

.select-category {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    padding: 20px 24px;
    background: var(--color-white-grey);
  }

  &__category-search {
    padding: 20px 24px;
    border-bottom: 1px solid var(--color-light-grey);
  }

  &__categories-list {
    padding: 0 24px;
    overflow: auto;

    .category-item {
      padding: 12px 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      border-bottom: 1px solid var(--color-light-grey);
      gap: 8px;

      &.prev-category {
        justify-content: flex-start;
      }

      &:hover {
        background: var(--color-white-grey);
      }
    }
  }

  &__button {
    margin-top: auto;
    border-top: 1px solid var(--color-light-grey);
    padding: 12px 24px;
    display: flex;
    justify-content: flex-end;
  }
}

.svg_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.star_icon {
  display: flex;
}
.c_icon {
  &:hover {
    cursor: pointer;
  }
}

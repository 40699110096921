.telegram-settings {
  .telegram-info {
    margin-top: 24px;
    display: flex;

    &__qr-code {
      background: #0FA4EC;
      width: 160px;
      height: 160px;
      padding: 32px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &__users {
      margin-left: 32px;

      .user-tags {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      .unsubscribe-all {
        margin-top: 16px;
        padding: 4px 8px;
        border-radius: 14px;
      }
    }
  }
}


.user-tag {
  padding: 4px 8px;
  background: var(--color-white-grey);
  gap: 8px;
  border-radius: 14px;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 4px;
  }

  &__icon {
    cursor: pointer;

    &:hover {
      color: var(--color-dark-grey);
    }
  }
}

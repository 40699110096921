@import 'src/styles/typography';

.product-add {
  max-width: 628px;

  &__content {
    background: var(--color-white);
    padding: 0 24px 24px 40px;

    .description {
      margin-top: 8px;
      max-width: 320px;
    }
  }
}

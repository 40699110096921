@import 'src/styles/variables';

.text {
  font-family: $font-primary;
  font-size: 14px;

  &.text-xxxl {
    font-size: 27px;
  }

  &.text-xxl {
    font-size: 24px;
  }

  &.text-xl {
    font-size: 19px;
    line-height: 24px;
  }

  &.text-lg {
    font-size: 16px;
    line-height: 20px;
  }

  &.text-md {
    font-size: 14px;
    line-height: 18px;
  }

  &.text-sm {
    font-size: 13px;
    line-height: 16px;
  }

  &.text-xs {
    font-size: 12px;
    line-height: 15px;
  }

  &.text-xxs {
    font-size: 11px;
    line-height: 14px;
  }
}

h1 {
  &.text {
    font-size: 28px;
    line-height: 35px;
    font-weight: 500;
  }
}

h2 {
  &.text {
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
  }
}

h3 {
  &.text {
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
  }
}

h6 {
  &.text {
    font-size: 28px;
    line-height: 30px;
    font-weight: 700;
  }
}

.color-primary {
  color: var(--color-primary-black);
}

.color-white {
  color: var(--color-white);
}

.color-gray {
  color: var(--color-middle-grey);
}

.color-gray-light {
  color: var(--color-light-grey);
}

.color-middle-grey {
  color: var(--color-middle-grey);
}

.color-dark-grey {
  color: var(--color-dark-grey);
}

.color-extra-grey {
  color: var(--color-extra-grey);
}

.color-red {
  color: var(--color-red);
}

.color-blue {
  color: var(--color-blue);
}

.text-start {
  text-align: left;
}

.text-end {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-regular {
  font-weight: 400;
}

.text-medium {
  font-weight: 500;
}

.text-semibold {
  font-weight: 600;
}

.text-bold {
  font-weight: 700;
}

.text-overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}

.text-overflow-fade {
  position: relative;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;

  &::after {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    content: '';
    width: 2em;
    background-image: linear-gradient(270deg, black 35%, transparent);
  }
}

.text-transform-lowercase {
  text-transform: lowercase;
}

.text-transform-uppercase {
  text-transform: uppercase;
}

.text-transform-capitalize {
  text-transform: capitalize;
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-decoration-line-through {
  text-decoration: line-through;
  //text-decoration-color: $deep-black;
  text-decoration-thickness: 2px;
}

.white-space-nowrap {
  white-space: nowrap;
}

.white-space-pre-line {
  white-space: pre-line;
}

.font-family-primary {
  font-family: $font-primary;
}

.font-family-secondary {
  font-family: $font-secondary;
}

.font-family-object-sans {
  font-family: $font-object-sans;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-auto {
  cursor: auto;
}

.required {
  &:after {
    content: '*';
    color: var(--color-red);
    margin-left: 2px;
  }
}

.required-color-grey {
  &.required {
    &:after {
      color: var(--color-text-grey);
    }
  }
}

.disable {
  //color: var(--color-middle-grey)-dark;
}

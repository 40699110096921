.header {
  padding: 10px;
  background: var(--color-white);

  border-bottom: 1px solid var(--color-light-grey);

  &__search_input {
    background: var(--color-light-grey);
  }
}

.layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;

  .main {
    display: flex;
    flex-grow: 1;

    .nav-wrapper {
      width: 224px;
      min-width: 224px;
      z-index: 9;
      background: var(--color-white-grey);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .content-wrapper {
      flex: 1;
      width: 100%;
      overflow-x: hidden;
      background-color: var(--color-white);
    }

    .verification {
      padding: 8px;
    }
  }
}

@import 'src/styles/media';

.select-address {
  display: flex;
  flex-direction: column;
  height: 100%;
  @media (min-width: $lg) {
    flex-direction: row-reverse;
  }

  &__map {
    flex: 1;
    min-height: 450px;
    max-height: 450px;
    @media (min-width: $sm) {
      min-height: 550px;
      max-height: 550px;
    }
    @media (min-width: $lg) {
      min-height: 100%;
      max-height: initial;
    }
  }

  &__form {
    width: 100%;
    padding: 24px 16px 50px;
    background: var(--color-white);
    @media (min-width: $lg) {
      max-width: 409px;
      min-width: 409px;
      padding: 24px 32px;
    }

    .button-back {
      display: none;
      padding: 6px 8px;
      background: var(--color-grey-light);
      color: var(--color-text-grey);
      @media (min-width: $lg) {
        display: block;
      }
    }

    .button-save {
      height: 46px;
    }

    .title {
      font-size: 23px;
      line-height: 24px;
      @media (min-width: $lg) {
        margin-top: 16px;
      }
    }

    .address-input-wrapper {
      position: relative;

      .options {
        margin-top: 10px;
        position: absolute;
        background: white;
        z-index: 2;
        border: 2px solid var(--color-light-grey);
        border-radius: 8px;
        padding: 16px;
        width: 100%;

        &__item {
          padding: 5px 0;
          transition: .3s color;

          &:hover {
            color: var(--color-text-grey);
          }

          &:not(:last-child) {
            margin-bottom: 5px;
            border-bottom: 1px solid var(--color-light-grey);
          }
        }
      }
    }

    .use-location {
      margin-top: 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      @media (max-width: $sm) {
        font-size: 13px;
      }

      p {
        margin-top: 2px;
        margin-left: 7px;
      }
    }

    .dots-loader {
      margin-top: 16px;
      margin-bottom: -4px;
    }

    .buttons {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      @media (min-width: $sm) {
        flex-direction: row-reverse;
      }

      .button-back-mobile {
        margin-top: 8px;
        background: var(--color-grey-light);
        color: var(--color-text-grey);
        @media (min-width: $sm) {
          margin-top: 0;
        }
        @media (min-width: $lg) {
          display: none;
        }
      }
    }
  }
}

.verification {
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  width: 100%;
  color: var(--color-white);

  &--new, &--moderation {
    background-color: var(--color-orange);
  }

  &--error {
    background-color: var(--color-red);
  }

  &--verified {
    background-color: var(--color-green);
  }

  .button {
    border: none;
    text-align: left;
    color: var(--color-white);
    text-decoration: underline;
    font-size: 14px;
  }
}

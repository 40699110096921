.drop-down-list {
  position: relative;
  display: flex;
  align-items: center;

  &__label {
    margin-right: 12px;
  }

  &__block {
    padding: 7px 8px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--color-light-grey);
    transition: .3s;
    cursor: pointer;

    &.show-list, &:hover {
      border-color: var(--color-middle-grey);
    }

    .icon {
      margin-left: 8px;
    }
  }

  &__dropdown {
    opacity: 0;
    position: absolute;
    transition: .3s ease;
    bottom: 0;
    right: 0;
    pointer-events: none;
    border-radius: 8px;
    width: auto;
    background: var(--color-white);
    z-index: 2;
    box-shadow: 0 2px 6px 0 var(--color-shadow);
    padding: 8px;

    &.active {
      bottom: 36px;
      opacity: 1;
      pointer-events: auto;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 32px;
      cursor: pointer;
      transition: .3s;
      padding: 8px;
      border-radius: 8px;
      min-width: 56px;

      &:hover {
        background: var(--color-light-grey);
      }
    }
  }
}

@import "src/styles/media";

.product-image {
  background: var(--color-grey-light);
  border-radius: 4px;
  overflow: hidden;

  &.border {
    padding: 4px;
  }

  &__image {
    width: 100%;
    height: 100%;
    background: var(--color-grey-light);
    display: flex;

    &.object-fit-cover {
      img {
        object-fit: cover;
      }
    }

    img {
      height: auto;
      object-fit: contain;
      width: 100%;
      mix-blend-mode: multiply;
    }
  }
}

.header {
  height: 36px;
  background: var(--color-primary-black);
  padding: 7px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 101;

  &__logo {
    display: block;
    max-width: 52px;
  }

  &__exit {
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
      p, i {
        color: var(--color-white);
      }
    }

    p, i {
      transition: 0.3s color;
    }

    i {
      margin-right: 8px;
    }
  }
}

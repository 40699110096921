.review {
  max-width: 564px;
  padding: 24px 0;
  border-bottom: 1px solid var(--color-light-grey);
  &__images {
    margin: 16px 0 0;
  }

  &__reviewer {
    margin-top: 16px;
  }

  &__date {
    margin-top: 4px;
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
  }

  &__reply_btn {
    background: var(--color-light-blue);
    font-weight: 500;
    color: var(--color-blue);
    margin-top: 16px;
  }
}

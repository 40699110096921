.partner-modal {
  padding: 32px;
  &__title {
    margin-bottom: 16px;
  }
  &__rowInputs {
    display: flex;
    gap: 8px;
    margin: 16px 0 32px 0;
  }
  &__rowInputs div {
    height: fit-content;
  }
  &__rowButtons {
    justify-content: end;
    display: flex;
    gap: 16px;
  }
}

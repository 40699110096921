@import "src/styles/typography";
@import "src/styles/variables";


.product-media-files {
  .image-requirements {
    margin-top: 12px;

    &__item {
      display: flex;

      p {
        line-height: 17px;

        & + p {
          margin-left: 6px;
        }
      }
    }
  }

  .loaded-images {
    margin-bottom: 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
}


.image-item {
  position: relative;

  &:hover {
    .image-item__close {
      display: flex;
    }
  }

  &__close {
    display: none;
    position: absolute;
    top: 4px;
    right: 4px;
    background: var(--color-primary-black);
    border-radius: 3px;
    height: 18px;
    width: 18px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

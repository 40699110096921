:root {
  --color-white: #fff;
  --color-primary-black: #212529;
  --color-light-grey: #f0f1f3;
  --color-lower-middle-grey: #b3b6c6;
  --color-middle-grey: #808a98;
  --color-divider-grey: #dadbdd;
  --color-dark-grey: #5e6774;
  --color-text-grey: #727686;
  --color-dark-grey-hover: #eaecf2;
  --color-extra-grey: #afb3b9;
  --color-white-grey: #f7f8f9;
  --color-light-blue: #e5f2ff;
  --color-light-blue-hover: #d1e5fa;
  --color-blue: #007aff;
  --color-blue-hover: #006de3;
  --color-blue-disable: rgba(0, 122, 255, 0.4);
  --color-light-grey-hover: #f1f2f4;
  --color--green: #1bcb74;
  --color-red: #ff453a;
  --color-green: #1acb74;
  --color-orange: #ffa32c;
  --color-shadow: #5e67741a;
  --color-grey-light: #f7f8fc;
}

$font-primary: 'TTHovesPro-Md', sans-serif;
$font-secondary: 'TTHovesPro-Rg', 'TTHovesPro-Md', sans-serif;
$font-object-sans: 'Object Sans Bold', sans-serif;

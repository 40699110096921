.layout {
  display: flex;

  .main {
    flex: 0 0 565px;
    max-width: 565px;
  }

  .nav {
    padding-left: 74px;

    &__prompt {
      margin-top: 24px;
      width: 200px;
      background: var(--color-white-grey);
      border-radius: 8px;
      padding: 16px;
    }
  }
}

@import "../../styles/variables";
@import "../../styles/typography";

.notifications {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 10px;
  width: 380px;
  z-index: 9999999;

  &__message_block {
    background: #fff;
    margin-bottom: 10px;
    border: 1px solid var(--color-white-grey);
  }
  &__block_top {
    padding: 10px;
  }

  &__message_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include MiniTextButton;
    letter-spacing: 0.04em;
    margin-bottom: 10px;
    color: #000000;
  }

  &__message_body {
    @include JostDigitTxt;
  }
  &__message_btn {
    border-top: 1px solid var(--color-white-grey);
    text-align: right;
    padding: 10px;
    button {
      background: var(--color-blue);
      border: 0;
      color: #fff;
      padding: 8px 20px;
      a {
        @include MiniTextButton;
      }
    }
  }

  &__animate {
    width: 12px;
    height: 12px;
    border-radius: 15px;
    border: 2px solid rgb(145, 231, 15);
    border-right: 2px solid rgb(255, 255, 255);
    animation-duration: 1s;
    animation-name: slidein;
    animation-iteration-count: infinite;
  }

  @keyframes slidein {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  &__notifications__sub_list {
    margin-left: 10px;
  }
  &__notifications__sub_list_name {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

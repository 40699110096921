.page_reviews {
  &__reviews {
    margin: 0 0 0 40px;
  }

  &__pagination {
    margin: 44px 0 0 40px;
  }
}

.header {
  &__sort_wrapper {
    margin-left: 10px;
    display: flex;
    align-items: center;
    column-gap: 12px;
  }
}

@import "src/styles/typography";

.navigation {
  position: sticky;
  top: 36px;
  border-right: 1px solid var(--color-light-grey);
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    width: 100%;
  }

  &__shop-info {
    padding: 4px 8px;
    position: relative;

    .select-shop {
      padding: 8px;
      display: flex;
      cursor: pointer;
      transition: .3s background-color;

      &:hover {
        background: var(--color-light-grey);
      }

      &__avatar {
        border-radius: 50%;
        width: 18px;
        height: 18px;
        margin-right: 8px;
      }

      &__name {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 75%;
      }

      &__icon {
        margin-left: auto;
      }
    }
  }

  &__shops-popup {
    position: absolute;
    background: white;
    width: 312px;
    max-height: 70vh;
    overflow: auto;
    top: 48px;
    padding: 8px;
    border-radius: 8px;
    z-index: 999;
    box-shadow: 0 2px 6px 0 var(--color-shadow);

    .popup-item {
      background: var(--color-white);
      padding: 8px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      transition: .3s background-color;

      &:hover {
        background: var(--color-light-grey);
      }

      &.active {
        background: var(--color-white-grey);
      }

      &__content {
        display: flex;
        align-items: center;

        .img {
          width: 18px;
          height: 18px;
          border-radius: 50%;
          margin-right: 8px;
        }

        i {
          margin-right: 8px;
        }
      }
    }
  }

  &__menu-links {
    padding: 8px;
  }
}

.menu-nav-link {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  border-radius: 6px;

  &:hover {
    background: var(--color-light-grey);
  }

  &.active {
    background: var(--color-light-blue);

    span, i {
      color: var(--color-primary-black);
    }
  }

  a {
    display: flex;
  }

  i {
    margin-right: 8px;
  }
}

.init-registration-modal {
  padding: 50px 65px;
}

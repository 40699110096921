.partner-drawer {
  &__header {
    padding: 20px 24px;
    background: var(--color-white-grey);
    border: 1px solid var(--color-light-grey);
    &__container {
      display: flex;
      gap: 28px;
      align-items: center;
    }
    &__img {
      height: 48px;
      width: 48px;
    }
  }

  &__content {
    padding: 24px 24px 0 24px;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 90px);
    &__info {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    &__chart {
      margin-top: auto;
      position: relative;
      margin-bottom: 24px;
    }
    &__chart-date {
      position: absolute;
      right: 0;
      top: 15px;
    }
    &__actionButton {
      display: flex;
      justify-content: end;
      padding: 12px 24px;
      box-sizing: border-box;
      border-top: 1px solid rgb(240, 241, 243);
    }
  }
}

.product-characteristics {
  .dl {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin: 0 0 8px 0;

    .dt {
      flex: 1;
      display: flex;
      position: relative;

      &:before {
        content: '';
        border-bottom: 1px dotted rgba(204, 214, 228, 0.6);
        bottom: 0.2em;
        display: block;
        left: 0;
        position: absolute;
        width: 100%;
      }

      span {
        padding-right: 5px;
      }
    }

    .dd {
      text-align: right;
      display: flex;
      flex-direction: column;
    }
  }
}

@import 'src/styles/media';

.preview-card {
  padding: 24px;

  .product-name {
    font-size: 31px;
    line-height: 39px;
    color: var(--color-primary-black);
  }

  .header {
    display: flex;
    gap: 24px;
    align-items: center;
    margin-top: 24px;
    @media (max-width: $sm) {
      justify-content: space-between;
    }

    &__block {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  .content {
    padding-bottom: 16px;
    display: flex;
    column-gap: 32px;
    @media (min-width: $sm) {
      padding: 16px 0;
    }
    @media (max-width: $lg) {
      flex-direction: column;
    }

    &__image_block {
      display: grid;
      column-gap: 8px;
      grid-template-columns: 92px 468px;

      .slider {
        box-sizing: border-box;
        flex-basis: 568px;
        display: flex;
        flex-direction: column;

        &-wrapper {
          height: auto;
        }

        &__item {
          display: flex;
          justify-content: center;
          align-items: center;
          aspect-ratio: 1 / 1;
          border-radius: 4px;
          overflow: hidden;
          box-sizing: border-box;

          & > div {
            padding: 4px;
          }
        }

        &__image_more {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 68px;
          margin-top: 8px;
          cursor: pointer;
          background: var(--color-grey-light);
        }
      }

      .main-photo {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        @media (min-width: $sm) {
          height: 460px;
        }
      }
    }

    &__info_block {
      flex-basis: 420px;

      .description {
        &__text {
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
          display: -webkit-box;
        }
      }

      .brand-name {
        display: flex;
        align-items: center;
      }
    }

    &__pay_block {
      @media (min-width: $md) {
        flex-basis: 264px;
      }

      .price, .shop {
        background: var(--color-light-grey);
        border-radius: 8px;
      }

      .price {
        padding: 16px;

        &__text {
          font-size: 25px;
        }

        &__button {
          margin-top: 12px;
          font-size: 14px;
          padding: 14px;
        }

        &__shipping_data {
          margin-top: 16px;
          display: flex;
          flex-direction: column;
          row-gap: 4px;
          @media (max-width: $md) and (min-width: $xs) {
            text-align: right;
          }
        }
      }

      .shop {
        padding: 12px 16px;
        display: flex;
        align-items: center;
        gap: 12px;
        margin-top: 8px;

        &__avatar {
          border-radius: 50%;
          width: 28px;
          height: 28px;
        }
      }
    }
  }

  .description_section {
    margin-bottom: 32px;
    max-width: 824px;
  }

  .characteristics_section {
    max-width: 824px;
  }
}

@import "src/styles/typography";
@import "src/styles/variables";

.product-info-row {
  display: flex;
  align-items: flex-start;

  & + .product-info-row {
    margin-top: 8px;
  }

  &__title {
    flex: 1 0 30%;
    display: flex;
    position: relative;

    &:before {
      content: "";
      border-bottom: 1px solid var(--color-dark-grey-hover);
      bottom: 4px;
      left: 0;
      position: absolute;
      width: 100%;
    }

    span {
      background: var(--color-white);
      padding-right: 4px;
      position: relative;
    }
  }
}


.page-tabs {
  display: flex;
}

.tab {
  & + .tab {
    margin-left: 16px;
  }

  cursor: pointer;
  padding: 8px;
  transition: .3s border-bottom-color;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &__title {
    color: var(--color-dark-grey);
  }

  &__label {
    padding: 1px 8px;
    border-radius: 8px;
    margin-left: 4px;
  }

  &.active {
    &:before {
      content: "";
      border-bottom: 2px solid var(--color-blue);
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: -9px;
    }

    .tab__title {
      color: var(--color-primary-black);
    }
  }

  &.disabled {
    pointer-events: none;

    .tab__title {
      color: var(--color-light-grey);
    }
  }
}


.color-primary {
  background: var(--color-light-grey);
  color: var(--color-dark-grey)
}

.color-red {
  background: #FFE3E3;
  color: #FF5959;
}

.color-blue {
  background: var(--color-light-blue);
  color: var(--color-blue);
}

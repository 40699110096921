@import '../../../../styles/variables';
@import '../../../../styles/typography';

.messages {
  display: grid;
  grid-template-areas:
    'header'
    'list'
    'send';
  grid-template-rows: auto 1fr auto;
  background: var(--color-white);

  &__header {
    grid-area: header;
  }

  &__list {
    grid-area: list;
    height: 100%;
  }

  &__send {
    grid-area: send;
  }
}

.uploadShopIcon {
  width: 160px;
  height: 160px;
  border-radius: 8px;
  border: 1px dashed var(--color-divider-grey);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 14px;

  &.disabled {
    background: var(--color-white-grey);
    pointer-events: none;

    .uploadShopIcon__icon {
      i {
        color: var(--color-extra-grey);
      }
    }

    .uploadShopIcon__title {
      p,
      span {
        color: var(--color-extra-grey);
      }
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    margin: 34px 0 5px;

    &--accept {
      span {
        color: var(--color-blue);
      }
    }

    &--reject {
      span {
        color: var(--color-red);
      }
    }

    p {
      span {
        margin-left: 5px;
      }
    }
  }

  &__description {
    font-size: 10px;
    line-height: 13px;
  }

  &__info {
    text-align: center;
  }

  &__image {
    width: 160px;
    height: 160px;
    border-radius: 8px;
    margin-top: 14px;
    display: block;
    object-fit: cover;
  }
}

.image-item {
  position: relative;
  width: 160px;
  height: 160px;

  &:hover {
    .image-item__close {
      display: flex;
    }
  }

  &__close {
    display: none;
    position: absolute;
    top: 4px;
    right: 4px;
    background: var(--color-primary-black);
    border-radius: 3px;
    height: 18px;
    width: 18px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

@mixin JostText {
  //styleName: Текст;
  font-family: Jost;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
}

@mixin rejected {
  color: #ff1111;
  background: #ffd3d3;
  border-radius: 2px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 12px;
  letter-spacing: 0.05em;
}

@mixin JostHeader {
  //styleName: Заголовок;
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}

@mixin JostAccentText {
  //styleName: Акцент текст;
  font-family: Jost;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0em;
}

@mixin JostBtnText {
  //styleName: Текст;
  font-family: Jost;
  font-size: 16px;
  font-weight: 400;
  line-height: 11px;
  letter-spacing: 0.02em;
}

@mixin JostTxtBtn {
  //styleName: Текстовая кнопка;
  font-family: Jost;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
}

@mixin JostDigitTxt {
  //styleName: цифровой текст;
  font-family: Jost;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.05em;
}

@mixin MiniTextButton {
  /* Мини текстовая кнопка */
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.04em;
}
@mixin TextButton {
  font-family: Jost;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 100% */
  letter-spacing: 0.32px;
}

@mixin JostDescription {
  //styleName: Описание;
  font-family: Jost;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.02em;
}

@import '../../../../styles/typography';

.container__images {
  padding: 10px;
  border-top: 1px solid var(--color-light-grey);
  img {
    width: 60px;
    height: 100px;
    border-radius: 10px;
  }
}

.bottom-messenger-panel {
  border-top: 1px solid var(--color-light-grey);
  display: flex;
  align-items: end;
  gap: 16px;
  width: 100%;
  padding: 8px 16px;
  position: sticky;
  bottom: 0;
  background: var(--color-white);
  z-index: 1;

  &__attache {
    cursor: pointer;
    width: 18px;
    height: 18px;
    margin-bottom: 12px;
  }

  &__input {
    height: 34px;
    border: 0;
    background: 0;
    padding: 10px 0 0 10px;
  }

  &__input_wrapper {
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
    //display: grid;
    //align-items: end;
    //grid-template-columns: auto 1fr;
    background: var(--color-light-grey);
    border-radius: 6px;
  }

  &__send {
    background: var(--color-divider-grey);
    max-width: 34px;
    min-width: 34px;
    width: 34px;
    margin-bottom: 6px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: background-color 0.3s ease;

    &.active {
      cursor: pointer;
      background: var(--color-blue);
    }

    i {
      margin-left: 3px;
    }
  }
}

.dashboard-page {
  &__header {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
  &__content {
    margin-left: 25px;
  }

  .product {
    margin: 16px 0 16px 40px;
    width: 500px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 12px;
    padding: 4px;
    border-radius: 8px;
    &:hover {
      background: var(--color-white-grey);
    }
    &__image {
      width: 43px;
      height: 36px;
    }
    &__title {
      padding-top: 4px;
    }
  }
}

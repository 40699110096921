.moderate-message {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__img {
    display: flex;
    justify-content: center;

    img {
      width: 100px;
      height: 100px;
    }
  }

  &__title {
    margin-top: 32px;
    font-size: 32px;
    line-height: 33px;
  }

  &__text {
    max-width: 275px;
    width: 100%;
    margin-top: 24px;

    p {
      span {
        margin-left: 3px;
      }
    }
  }
}

.reply {
  &__set_actions {
    margin-top: 16px;
  }

  &__text_wrapper {
    padding: 16px;
    border-radius: 12px;
    background: var(--color-light-blue);
    margin-top: 24px;
  }

  &__text {
    color: #000;
    margin-bottom: 8px;
  }

  &__date {
    color: var(--color-dark-grey);
  }

  &__text_footer {
    display: flex;
    justify-content: space-between;
  }

  &__edit {
    margin-right: 16px;
    cursor: pointer;
  }

  &__delete {
    cursor: pointer;
  }
}

.reply_input {
  margin-top: 24px;

  &__actions_btn {
    font-weight: 400;
    column-gap: 8px;
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
  }
}

.modal {
  padding: 32px;

  &__buttons {
    column-gap: 16px;
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;

    button {
      font-weight: 500;
    }
  }
}

@import "src/styles/media";

.reg-steps {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
  @media (min-width: $sm) {
    row-gap: 16px;
  }

  &__list {
    display: flex;
    column-gap: 8px;

    .step {
      width: 54px;
      height: 2px;
      background: var(--color-dark-grey-hover);

      &-active {
        background: #b3d7ff;
        animation: step 1s ease-in-out infinite;
      }

      &-done {
        background: var(--color-blue);
      }
    }
  }
}


@keyframes step {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.2;
  }
}

.close-icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: .3s background-color;
}

.color-primary {
  background: var(--color-white);

  &:hover {
    background: var(--color-white-grey);
  }
}

.color-white-grey {
  background: var(--color-white-grey);

  &:hover {
    background: var(--color-light-grey);
  }
}

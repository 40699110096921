@import "src/styles/media";

.auth-layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  .logo {
    max-width: 145px;
  }

  &__modal {
    margin-top: 48px;
    background: var(--color-white);
    border-radius: 12px;
    padding: 48px 40px 24px;
    max-width: 420px;
    width: 100%;

    .title {
      font-size: 31px;
      line-height: 32px;
    }

    .sub-title {
      margin-top: 4px;
      font-size: 18px;
      line-height: 19px;
    }
  }
}

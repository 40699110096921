.tooltip {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__content {
    box-shadow: 0 2px 6px 0 var(--color-shadow);
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 0;
    position: absolute;
    width: fit-content;
    min-width: 140px;
    z-index: 9;
    top: 32px;
    right: 0;
    pointer-events: none;
    cursor: pointer;

    & > * {
      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }

    &.active {
      opacity: 1;
      pointer-events: auto;
    }
  }
}


.view-dots {
  &:hover {
    .tooltip__icon {
      background: #808A9826;
    }
  }

  .tooltip {
    &__icon {
      width: 24px;
      height: 24px;
      border-radius: 3px;
    }
  }

  &.tooltip__content {
    background: var(--color-white);
    padding: 8px;
    border-radius: 8px;
    position: fixed;
  }
}

.view-question {
  .tooltip {
    &__icon {
      i {
        color: var(--color-middle-grey);
      }
    }
  }

  &.size-md {
    &.tooltip__content {
      width: 190px;
      margin-left: -95px;
    }
  }

  &.size-sm {
    &.tooltip__content {
      width: 150px;
      margin-left: -75px;
    }
  }

  &.tooltip__content {
    background: var(--color-text-grey);
    padding: 12px 16px;
    border-radius: 4px;
    width: 190px;
    color: var(--color-white);
    top: 170%;
    left: 50%;
    margin-left: -95px;
    font-size: 11px;
    line-height: 14px;
    white-space: pre-line;

    &::after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -8px;
      border-width: 8px;
      border-style: solid;
      border-color: transparent transparent var(--color-text-grey) transparent;
    }
  }
}

.product-navigation-menu-item {
  cursor: pointer;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &.active {
    .product-navigation-menu-item__circle {
      background: var(--color-extra-grey);
    }

    .product-navigation-menu-item__text {
      color: var(--color-primary-black);
    }
  }

  &.completed {
    .product-navigation-menu-item__circle {
      background: var(--color-middle-grey);
    }

    .product-navigation-menu-item__text {
      color: var(--color-middle-grey);
    }
  }

  &__circle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: var(--color-divider-grey);
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text {
    color: var(--color-extra-grey);
  }
}

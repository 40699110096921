.selected-menu {
  position: fixed;
  bottom: -100px;
  left: 50%;
  transform: translateX(-50%);
  background: var(--color-primary-black);
  display: flex;
  align-items: center;
  padding: 16px 24px;
  border-radius: 8px;
  transition: .3s;
  opacity: 0;

  &.active {
    bottom: 64px;
    opacity: 1;
  }

  &__content {
    margin-left: 24px;
    display: flex;
    gap: 8px;
  }

  &__close {
    margin-left: 24px;
    cursor: pointer;
  }
}


.button {
  background: #5E677433;
  padding: 8px;
  display: flex;
  cursor: pointer;
  border-radius: 6px;
  transition: .3s background-color;

  &:hover {
    background: #5E677480;
  }

  &__text {
    margin-left: 8px;
  }
}

@import "src/styles/variables";

.table {
  overflow-x: auto;
  overflow-y: hidden;

  .tr {
    width: fit-content;
    height: 34px;
    border-bottom: 1px solid var(--color-light-grey);
    display: flex;

    &.with-select {
      position: relative;

      &.is-selected-row {
        border-color: #9ACAFF;

        &:before {
          content: '';
          position: absolute;
          border-top: 1px solid #9ACAFF;
          width: 100%;
          top: -1px;
        }

        & + .is-selected-row {
          &:before {
            content: none;
          }
        }

        .td {
          background: #e4f2ff;
        }
      }
    }

    &:hover, &.is-selected-row {
      .checkbox-cell-wrapper {
        display: block;
      }
    }
  }

  .th, .td {
    line-height: 18px;
    padding: 8px 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
    background: var(--color-white);

    &.checkbox-cell {
      padding: 10px 0 10px 10px;
    }

    &.feature-list-cell {
      overflow: initial;
      padding: 4px 13px;
    }
  }

  .td {
    font-family: $font-secondary;
    font-weight: 400;
    overflow: hidden;

    &.with-resize {
      position: relative;

      &.is-resizing {
        &:before {
          content: "";
          height: calc(100% + 1px);
          top: -1px;
          width: 1px;
          background: #1c84d7;
          position: absolute;
          right: 0;
        }
      }
    }
  }

  .th {
    position: relative;
    color: var(--color-middle-grey);
    font-size: 11px;
    display: flex;
    font-family: $font-primary;

    &.with-sorting {
      cursor: pointer;

      &:hover {
        background: var(--color-white-grey);

        &:not(&.is-sorted) {
          .sorting__icon-hidden {
            display: block;
          }
        }
      }
    }

    &.is-sorted {
      background: var(--color-white-grey);
      color: var(--color-primary-black);
    }
  }

  .tbody {
    .tr {
      &:not(&.is-selected-row) {
        &:hover {
          .td {
            background: var(--color-white-grey);
          }
        }
      }
    }

    .checkbox-cell-wrapper {
      display: none;
    }
  }

  .not-found {
    margin: 8px 16px;
  }

  .cursor-pointer {
    cursor: pointer;
  }
}

.settings {
  &__header {
    &-wrapper {
      padding-bottom: 8px;
    }
  }

  &__content {
    max-width: 564px;

    &-wrapper {
      padding: 24px 32px;
    }
  }
}

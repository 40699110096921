@import 'src/styles/variables';

.button {
  font-family: $font-secondary;
  font-size: 13px;
  line-height: 14px;
  display: block;
  border: 1px solid transparent;
  cursor: pointer;
  outline: 0;
  color: var(--color-white);
  background-color: var(--color-blue);
  appearance: none;
  border-radius: 6px;
  padding: 8px;

  &:hover {
    background-color: var(--color-blue-hover);
  }

  &:disabled {
    cursor: default;
    box-shadow: none;
    color: var(--color-extra-grey);
    background: var(--color-light-grey);
  }

  &.fluid {
    width: 100%;
  }

  &.icon {
    display: flex;
    align-items: center;
    gap: 6px;
  }
}

.view-primary {
  //background-color: var(--color-blue);

  &:hover {
    //background-color: var(--color-blue)-hover;
  }
}

.view-secondary {
  background-color: var(--color-white);
  color: var(--color-dark-grey);
  border: 1px solid var(--color-divider-grey);

  &:hover {
    background-color: var(--color-white-grey);
  }

  &:disabled {
    background: var(--color-light-grey);
  }
}

.view-link {
  line-height: 18px;
  font-weight: 400;
  padding: 0;
  background: none;
  min-height: auto;
  color: var(--color-blue);

  &:hover {
    color: var(--color-blue-hover);
    background: none;
  }
}

.size-xs {
  font-size: 12px;
  padding: 4px 9px;
}

.size-sm {
  padding: 7px 12px;
  font-size: 14px;
  line-height: 18px;
}

.size-md {
  letter-spacing: 1.25px;
  font-weight: 500;
  padding: 10px 15px;

  &.icon {
    gap: 10px;
  }
}

.size-lg {
  font-size: 14px;
  font-weight: 500;
  padding: 16px 24px;
  border-radius: 8px;
}

.color-white {
  background: var(--color-white);
  color: var(--color-dark-grey);

  &:hover {
    background: var(--color-dark-grey-hover);
  }
}

.color-black {
  color: var(--color-primary-black);
}

.color-light-blue {
  background: var(--color-light-blue);
  color: var(--color-blue);

  &:hover {
    background: var(--color-light-blue-hover);
  }
}

.color-middle-grey {
  background: var(--color-middle-grey);
  color: var(--color-white);

  &:hover {
    background: var(--color-dark-grey);
  }
}

.button-group {
  display: flex;
  margin-left: -6px;
  margin-right: -6px;

  .button {
    margin-left: 6px;
    margin-right: 6px;
  }

  &.gap-8 {
    margin-left: -8px;
    margin-right: -8px;

    .button,
    & > * {
      margin-left: 8px;
      margin-right: 8px;
    }
  }

  &.gap-16 {
    margin-left: -16px;
    margin-right: -16px;

    .button {
      margin-left: 16px;
      margin-right: 16px;
    }
  }

  &.justify-content-space-between {
    justify-content: space-between;
  }
}

@import '../../../../styles/typography';
.header {
  background: #fff;
  padding: 19px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-bottom: 1px solid var(--color-light-grey);

  &__image {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 50%;
  }
  &__name {
    @include JostTxtBtn;
  }
}

.tooltip {
  position: absolute;
  top: 18px;
  right: 24px;

  &__content {
    display: flex;
    align-items: center;
    gap: 8px;

    .text {
      margin-top: 2px;
    }
  }
}

@import "src/styles/media";

.phone-auth {
  .number {
    font-size: 14px;
    line-height: 14px;
  }

  .description {
    margin-top: 8px;
    font-size: 14px;
    line-height: 16px;
  }

  .change-number {
    display: flex;
    align-items: center;

    button {
      margin-left: 4px;
    }
  }

  .otp-input {
    font-size: 44px !important;
    line-height: 45px !important;
    padding: 18px !important;
    height: 78px !important;
  }

  .get-new-code {
    max-width: 245px;
    margin: 0 auto;

    span {
      color: var(--color-primary-black);
      margin-left: 4px;
    }
  }

  .button-submit {
    height: 46px;
  }
}

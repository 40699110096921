@import "src/styles/media";

.registration-wrapper {
  padding: 20px 16px;
  @media (min-width: $sm) {
    padding: 48px;
  }

  &__logo {
    display: flex;
    justify-content: center;
    margin-bottom: 28px;

    img {
      width: 103px;
      height: auto;
    }
  }

  &__title {
    font-size: 20px;
    line-height: 20px;
  }
}

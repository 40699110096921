@import 'src/styles/variables';

.icon {
  &.size-xxl {
    font-size: 22px;
  }

  &.size-xl {
    font-size: 20px;
  }

  &.size-lg {
    font-size: 18px;
  }

  &.size-md {
    font-size: 16px;
  }

  &.size-sm {
    font-size: 14px;
  }

  &.size-xs {
    font-size: 12px;
  }
}

.color-primary {
  color: var(--color-primary-black);
}

.color-red {
  color: var(--color-red);
}

.color-white {
  color: var(--color-white);
}

.color-dark-grey {
  color: var(--color-dark-grey);
}

.color-middle-grey {
  color: var(--color-middle-grey);
}

.color-extra-grey {
  color: var(--color-extra-grey);
}

.color-blue {
  color: var(--color-blue);
}

.hover {
  cursor: pointer;
  transition: 0.2s;

  &-gray {
    &:hover {
      color: var(--color-middle-grey);
    }
  }

  &-dark {
    &:hover {
      color: var(--color-primary-black);
    }
  }
}

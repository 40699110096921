@import "src/styles/media";


.home-page {
  position: relative;
  background: var(--color-light-blue);

  &::before,
  &::after {
    content: "";
    background-repeat: no-repeat;
    display: block;
    position: absolute;
    background-size: 220px;
    inset: 0;
    width: 100%;
  }

  &::before {
    background-image: url("../../public/saw.svg");
    background-position-x: 134px;
    background-position-y: 100%;
  }

  &::after {
    background-image: url("../../public/brush.svg");
    background-position-x: 100%;
    background-position-y: 50%;
  }

  @media (max-width: 850px) {
    &::before {
      background-position-x: 40px;
    }
  }

  @media (max-width: 767px) {
    &::before {
      background-position-x: -20px;
    }

    &::after {
      background-position-x: 125%;
    }
  }
}


.layout {
  background-image: none;
  background-repeat: no-repeat;
  background-size: 220px;
  background-position-y: 66px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  @media (min-width: $sm) {
    background-image: url("../../public/screwdriver.svg");
  }

  .header {
    display: none;
    position: relative;
    z-index: 2;
    padding: 32px 44px 0;
    justify-content: end;
    @media (min-width: $sm) {
      display: flex;
    }
  }

  .main {
    margin: 32px auto 0;
    flex: 1;
    height: 100%;
    max-width: 581px;
    width: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: 0 28px;
    @media (min-width: $sm) {
      margin: 67px auto 0;
      padding: 0;
    }
    @media (min-width: $lg) {
      max-width: 610px;
    }

    .logo {
      max-width: 162px;

      @media (min-width: $sm) {
        max-width: 234px;
      }

      @media (min-width: $lg) {
        max-width: 300px;
      }
    }

    .title {
      font-size: 22px;
      line-height: 28px;
      font-weight: 600;
      @media (min-width: $sm) {
        font-size: 40px;
        line-height: 46px;
      }
      @media (min-width: $lg) {
        font-size: 42px;
        line-height: 48px;
      }
    }

    .description {
      font-size: 16px;
      line-height: 24px;
      @media (min-width: $sm) {
        font-size: 18px;
        line-height: 28px;
      }
      @media (min-width: $lg) {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }

  .footer {
    display: flex;
    margin: 0 auto 32px auto;
    column-gap: 48px;
    position: relative;
    z-index: 10;
    @media (max-width: 767px) {
      flex-direction: column;
      text-align: center;
      row-gap: 18px;
    }

    &__link {
      font-size: 14px;
      line-height: 14px;
      @media (min-width: $sm) {
        font-size: 18px;
        line-height: 18px;
      }
      @media (min-width: $lg) {
        font-size: 20px;
        line-height: 20px;
      }
    }
  }
}


.link {
  border-radius: 40px;
  width: 100%;
  font-family: inherit;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;

  &.black {
    background-color: var(--color-primary-black);
    color: var(--color-white);
    height: 64px;
    font-size: 22px;
    max-width: 321px;
    margin-top: 32px;
    @media (min-width: $lg) {
      margin-top: 44px;
    }
  }

  &.white {
    background-color: var(--color-white);
    color: var(--color-primary-black);
    height: 41px;
    font-size: 16px;
    max-width: 182px;
  }

  &.isMobile {
    display: none;

    @media (max-width: 767px) {
      display: inline-flex;
    }
  }
}

.resizer {
  position: absolute;
  top: 5%;
  height: 90%;
  width: 5px;
  border-radius: 2px;
  background: var(--color-light-blue-hover);
  cursor: col-resize;
  user-select: none;
  touch-action: none;
  z-index: 1;
  opacity: 0;

  &:not(.disabled) {
    &:hover {
      opacity: 1;
    }
  }

  &.ltr {
    right: -2px;
  }

  &.rtl {
    left: -2px;
  }

  &.is-resizing {
    opacity: 1;
    background: var(--color-blue);
  }

  &.disabled {
    cursor: default;
  }
}


.sorting {
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__icon {
    &-hidden {
      display: none;

      &-asc {
        margin-bottom: -5px;
      }
    }
  }
}


.features-list {
  display: flex;
  flex-direction: column;

  &__item {
    display: flex;
    align-items: center;
    height: 32px;
    padding: 8px;

    &:hover {
      background: var(--color-light-grey);
    }

    .icon {
      margin-right: 8px;
      color: var(--color-middle-grey);
    }

    .text {
      color: var(--color-dark-grey);
    }
  }
}

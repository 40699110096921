@import '../../../../styles/typography';

.dialogs {
  &__avatar {
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-light-grey);
    border-radius: 50%;
  }

  &__right {
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex-grow: 1;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__item {
    display: grid;
    grid-template-columns: 40px 1fr auto;
    padding: 10px;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    &_active,
    &:hover {
      background: var(--color-white-grey);
    }
  }

  &__row_date {
    color: #999;
    font-size: 14px;
    line-height: 12px;
  }

  &__row_last_message {
    display: grid;
    align-items: center;
    justify-content: space-between;
    grid-template-columns: 1fr 10px;
    color: #999;
    height: 20px;
    position: relative;
  }

  &__row_last_message_text {
    margin-right: 35px;
    font-weight: 400;
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__row_counter {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1c84d7;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    color: #fff;
    font-size: 12px;
  }
}

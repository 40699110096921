.product-add-search-card {
  display: flex;
  align-items: center;
  gap: 14px;

  &__title {
    padding: 4px 0;
  }

  &__button {
    margin-left: auto;
    padding: 6px 0;
    pointer-events: auto;
  }
}

.modal {
  padding: 32px;

  &__product {
    margin-top: 16px;
    padding: 8px 8px 8px 0;
    background: var(--color-grey-light);
    display: grid;
    grid-template-columns: 40px 1fr;
    align-items: center;
    column-gap: 8px;
  }

  &__input {
    margin-top: 16px;
    width: 124px;
  }

  &__quantity {
    margin-top: 16px;
  }

  &__buttons {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 16px;
  }
}

@import "src/styles/variables";


%extend_animation_slide {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  transform-origin: 0 0;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-play-state: paused;
}


.rc-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  line-height: 14px;
  position: relative;
  display: inline-block;
  cursor: pointer;
  max-width: 100%;
  font-family: $font-secondary;
  font-size: 14px;
  font-weight: 400;
  transition: all 0.3s ease;
  border: 1px solid var(--color-light-grey);
  border-radius: 6px;

  &.fluid {
    width: 100%;
  }

  &.error {
    border: 1px solid var(--color-red);
  }

  &:not(&.error) {
    &:focus,
    &:hover,
    &:active {
      border-color: var(--color-divider-grey);
    }
  }

  &.view-auth {
    &.rc-select {
      border-width: 2px;
      border-radius: 8px;

      &:not(&.error) {
        &.rc-select-focused {
          box-shadow: none;
        }
      }

      &-single {
        .rc-select-selector {
          .rc-select-selection {
            &-item {
              font-size: 16px;
              line-height: 16px;
              margin-left: 16px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }

      &-show-arrow {
        .rc-select-arrow {
          right: 16px;
        }

        .rc-select-selector {
          padding: 18px 42px;
        }
      }

      &-allow-clear {
        .rc-select {
          &-clear {
            top: 20px;
          }
        }
      }
    }

    &.has-label {
      &.rc-select {
        &-single {
          .rc-select-selector {
            .rc-select-selection {
              &-item {
                top: 26px;
                transform: translateY(0);
              }
            }
          }

          &:not(.rc-select-customize-input) .rc-select-selector {
            .rc-select-selection-search-input {
              font-size: 16px;
              line-height: 16px;
              margin-top: 6px;
            }
          }
        }
      }
    }

    .rc-select {
      &-selector {
        height: 56px;
        padding: 18px 16px;
      }

      &-selection-placeholder {
        top: 50%;
        transform: translateY(-50%);
        left: 16px;
        font-size: 16px;
      }
    }
  }

  &:not(&.error) {
    &.rc-select-focused {
      border-color: var(--color-blue) !important;
      box-shadow: 0 0 0 3px var(--color-light-blue);
    }
  }

  &-disabled {
    cursor: default;

    input {
      cursor: default;
    }

    .rc-select-selector {
      opacity: 0.3;
    }

    &:hover {
      border: 1px solid gray;
      box-shadow: 0 0 5px transparent;
    }
  }


  &-selector {
    display: flex;
    flex-wrap: wrap;
    background: var(--color-white);
    height: 32px;
    padding: 8px 12px;
    transition: all 0.5s ease;
    border-radius: 6px;
  }


  &-selection-placeholder {
    font-weight: 400;
    font-family: $font-secondary;
    pointer-events: none;
    position: absolute;
    left: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--color-middle-grey);
  }

  &-allow-clear {
    .rc-select-selector {
      padding-right: 30px;
    }

    .rc-select-clear {
      position: absolute;
      padding: 2px;
      right: 10px;
      top: 8px;
      display: flex;
      align-items: center;
      border-radius: 4px;

      &:hover {
        background: var(--color-light-grey);
      }
    }

    &.rc-select-show-arrow {
      .rc-select-selector {
        padding-right: 68px;
      }

      .rc-select-clear {
        right: 36px;
        color: var(--color-light-grey);
        font-size: 20px;

        &:hover {
          //color: $deep-black;
        }
      }
    }
  }

  &-show-arrow {
    &.rc-select-multiple .rc-select-selector {
      padding-right: 38px;
    }

    &.rc-select-open {
      .rc-select-arrow {
        transform: rotate(180deg);
      }
    }

    .rc-select-arrow {
      pointer-events: none;
      position: absolute;
      right: 8px;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }


  &-single {
    .rc-select-selector {
      display: flex;
      position: relative;

      .rc-select-selection {
        &-search {
          width: 100%;
          height: 100%;
        }

        &-search-input {
          width: 100%;
          cursor: pointer;
        }

        &-item {
          font-family: $font-secondary;
          font-weight: 500;
          font-size: 14px;
          line-height: 14px;
          color: var(--color-primary-black);
          position: absolute;
          left: 0;
          top: 9px;
          right: 60px;
          width: calc(100% - 50px);
          pointer-events: none;
          display: flex;
          align-items: center;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-left: 8px;
        }
      }
    }

    &:not(.rc-select-customize-input) .rc-select-selector {
      .rc-select-selection-search-input {
        font-family: $font-secondary;
        font-size: 14px;
        font-weight: 500;
        border: none;
        outline: none;
        width: 100%;
        height: 100%;
        background: inherit;
        color: var(--color-primary-black);
      }
    }
  }


  &-multiple &-selector {
    overflow: hidden;
    box-sizing: border-box;
    height: auto;
    min-height: 32px;
    padding: 4px 4px 0 4px;

    .rc-select-selection {
      &-overflow {
        display: flex;
        flex-wrap: wrap;

        &-item {
          max-width: 100%;
          display: inline-flex;
          align-items: center;
          margin-right: 4px;
          margin-bottom: 4px;

          &-suffix {
            margin-left: 8px;
          }
        }
      }

      &-item {
        display: flex;
        align-items: center;
        flex: none;
        background: var(--color-light-grey);
        border-radius: 3px;
        padding: 4px 8px;
        width: 100%;

        &-remove {
          width: 16px;
          height: 16px;
          border-radius: 4px;
          margin-left: 8px;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            background: var(--color-divider-grey);

            i {
              color: var(--color-middle-grey);
            }
          }
        }
      }

      &-search {
        position: relative;
        max-width: 100%;

        &-input {
          padding: 0;
          font-family: inherit;
          border: none;
          outline: none;
          background: none;
          width: 100%;
        }

        &-mirror {
          font-family: inherit;
          position: absolute;
          z-index: 999;
          white-space: nowrap;
          left: 0;
          top: 0;
          bottom: 0;
          visibility: hidden;
        }
      }
    }
  }


  &-dropdown {
    box-shadow: 0 8px 22px 0 var(--color-shadow);
    position: absolute;
    background: var(--color-white);
    border-radius: 6px;
    overflow: hidden;
    z-index: 500;
    min-height: 100px;
    padding: 8px;

    &-hidden {
      display: none;
    }

    &-slide-up-enter {
      @extend %extend_animation_slide;

      &.rc-select-dropdown-slide-up-enter-active {
        &.rc-select-dropdown-placement-bottomLeft {
          animation-name: rcSelectDropdownSlideUpIn;
          animation-play-state: running;
        }

        &.rc-select-dropdown-placement-topLeft {
          animation-name: rcSelectDropdownSlideDownIn;
          animation-play-state: running;
        }
      }
    }

    &-slide-up-appear {
      @extend %extend_animation_slide;

      &.rc-select-dropdown-slide-up-appear-active {
        &.rc-select-dropdown-placement-bottomLeft {
          animation-name: rcSelectDropdownSlideUpIn;
          animation-play-state: running;
        }

        &.rc-select-dropdown-placement-topLeft {
          animation-name: rcSelectDropdownSlideDownIn;
          animation-play-state: running;
        }
      }
    }

    &-slide-up-leave {
      animation-duration: 0.3s;
      animation-fill-mode: both;
      transform-origin: 0 0;
      opacity: 1;
      animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
      animation-play-state: paused;

      &.rc-select-dropdown-slide-up-leave-active {
        &.rc-select-dropdown-placement-bottomLeft {
          animation-name: rcSelectDropdownSlideUpOut;
          animation-play-state: running;
        }

        &.rc-select-dropdown-placement-topLeft {
          animation-name: rcSelectDropdownSlideDownOut;
          animation-play-state: running;
        }
      }
    }
  }


  &-item {
    font-family: $font-secondary;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    padding: 8px;
    border-radius: 6px;
    cursor: pointer;

    &-group {
      color: var(--color-middle-grey);
      font-weight: bold;
      font-size: 80%;
    }

    &-option {
      position: relative;

      &-content {
        padding-right: 20px;
      }

      &-disabled {
        color: var(--color-middle-grey);
      }

      &-grouped {
        padding-left: 24px;
      }

      &-active {
        background: var(--color-light-grey);
      }

      &-state {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        padding: 8px;
        pointer-events: none;
        display: flex;
        align-items: center;
      }
    }

    &-empty {
      text-align: center;
      color: var(--color-middle-grey);
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}


.view-disabled-search-select-dropdown {
  .rc-select {
    &-item {
      pointer-events: none;
      padding: 0 0 8px 0;

      &:last-child {
        padding: 0;
      }

      &-option {
        &-content {
          padding-right: 0;
        }

        &-active {
          background: initial;
        }
      }
    }
  }
}


// check style

.rc-select-multiple .rc-select-selector {
  .rc-select-selection-item-disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &-item {
    &-option {
      .rc-select-item-option-state {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        padding: 5px;
        pointer-events: none;
        width: 26px;
        height: auto;
        border-radius: 4px;
        border: 2px solid #3c8dbc;
        margin: 4px;
      }
    }
  }
}

.rc-select-selection-search-input {
  appearance: none;

  &::-webkit-search-cancel-button {
    display: none;
    appearance: none;
  }
}


.rc-select-disabled,
.rc-select-disabled input {
  cursor: not-allowed;
}

.rc-select-disabled {
  .rc-select-selector {
    opacity: 0.3;
  }
}

.rc-select-allow-clear {
  &.rc-select-multiple {
    .rc-select-selector {
      padding-right: 20px;
    }
  }
}

.rc-select-focused {
  .rc-select-selector {
    border-color: blue !important;
  }
}


.rc-select-item-empty {
  text-align: center;
  color: #999;
}

.rc-select-selection__choice-zoom {
  transition: all 0.3s;
}

.rc-select-selection__choice-zoom-appear {
  opacity: 0;
  transform: scale(0.5);

  &.rc-select-selection__choice-zoom-appear-active {
    opacity: 1;
    transform: scale(1);
  }
}

.rc-select-selection__choice-zoom-leave {
  opacity: 1;
  transform: scale(1);

  &.rc-select-selection__choice-zoom-leave-active {
    opacity: 0;
    transform: scale(0.5);
  }
}


.rc-select {
  &-selection {
    &-search-input {
      appearance: none;
      font-family: "Roboto", "Open Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
    }

    &-search-input::-webkit-search-cancel-button {
      display: none;
      appearance: none;
    }

    &__choice-zoom {
      transition: all 0.3s;
    }

    &__choice-zoom-appear {
      opacity: 0;
      transform: scale(0.5);

      &.rc-select-selection__choice-zoom-appear-active {
        opacity: 1;
        transform: scale(1);
      }
    }

    &__choice-zoom-leave {
      opacity: 1;
      transform: scale(1);

      &.rc-select-selection__choice-zoom-leave-active {
        opacity: 0;
        transform: scale(0.5);
      }
    }
  }

  &.rc-select-show-search {
    .rc-select-selection-search {
      .rc-select-selection-search-input {
        cursor: pointer;
      }
    }
  }

  &-focused &-selector {
    border-color: var(--color-middle-grey) !important;
  }

  &__control {
    border-color: unset !important;
    border-style: unset !important;
    border-width: unset !important;
    border-radius: 5px !important;
  }
}


@keyframes rcSelectDropdownSlideUpIn {
  0% {
    opacity: 0;
    transform-origin: 0 0;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform-origin: 0 0;
    transform: scaleY(1);
  }
}

@keyframes rcSelectDropdownSlideUpOut {
  0% {
    opacity: 1;
    transform-origin: 0 0;
    transform: scaleY(1);
  }
  100% {
    opacity: 0;
    transform-origin: 0 0;
    transform: scaleY(0);
  }
}

@keyframes rcSelectDropdownSlideDownIn {
  0% {
    transform: scaleY(0);
    transform-origin: 100% 100%;
    opacity: 0;
  }

  100% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}

@keyframes rcSelectDropdownSlideDownOut {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }

  100% {
    transform: scaleY(0);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}

@keyframes rcSelectLoadingIcon {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

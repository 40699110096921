.chat_wrapper {
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: hidden;
}

.chat {
  display: grid;
  border: 1px solid var(--color-light-grey);
  grid-template-columns: 312px 1fr;
  grid-template-rows: 1fr;
  margin: 24px 24px 24px 40px;
}

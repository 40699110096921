.email-auth {
  .number {
    font-size: 14px;
    line-height: 14px;
  }

  .description {
    margin-top: 8px;
    font-size: 14px;
    line-height: 16px;
  }

  .button-submit {
    height: 46px;
  }
}

.product-errors {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    background: var(--color-light-grey);
    padding: 20px 24px;
  }

  &__content {
    padding: 16px 24px;
    overflow: auto;

    article + article {
      margin-top: 32px;
    }

    .images-block {
      display: flex;
      gap: 10px;

      &__image-item {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          inset: 0;
          width: 100%;
          height: 100%;
          background: #FF453A1A;
          border-radius: 4px;
        }
      }
    }

    .add-new-photos {
      span {
        cursor: pointer;
        border-bottom: 1px dashed var(--color-blue);
        margin: 0 4px;
      }
    }
  }

  &__footer {
    margin-top: auto;
    border-top: 1px solid var(--color-light-grey);
    padding: 12px 24px;
    display: flex;
    justify-content: flex-end;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Jost:wght@200;400;500;600&display=swap");
@import './fonts/icons/style.css';
@import './_variables.scss';
@import './_fonts.scss';
@import '../components/UI/grid/grid.scss';
@import '../components/UI/icon/icon.scss';
@import '../components/UI/select/select.scss';
@import '../components/UI/calendar/calendar.scss';
@import '../components/UI/modal/modal.scss';

html, body, #root {
  height: 100%;
}


html {
  box-sizing: border-box;
}

img {
  pointer-events: none;
}

* {
  box-sizing: inherit;
  outline: none;
  padding: 0;
  margin: 0;
  list-style: none;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: $font-primary;
  margin: 0;
  padding: 0;
  background: #f2f2f2;
}

li {
  display: block;
}

.br {
  background: #f7f7f7;
  width: 100%;
  height: 1px;
}


.toastify-value {
  &__title {
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 500;
    color: var(--color-primary-black)
  }

  &__value {
    font-size: 12px;
    line-height: 1.67;
    color: #8b8b8b;
  }
}

.Toastify__close-button > svg {
  fill: currentColor;
  height: 24px !important;
  width: 24px !important;
}


.swiper {
  width: 100%;
  height: 100%;

  &-slide-thumb-active {
    border: 2px solid var(--color-blue);
    border-radius: 4px;
    box-sizing: border-box;
  }
}

.rc-select-dropdown {
  position: relative;
  z-index: 1000;
}

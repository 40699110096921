.search-category-input {
  z-index: 999;
  position: relative;

  &__options {
    position: absolute;
    top: 60px;
    background: var(--color-white);
    padding: 8px 12px;
    max-height: 230px;
    border-radius: 8px;
    box-shadow: 0 8px 22px 0 var(--color-shadow);
    overflow: auto;

    .option {
      padding: 8px;
      gap: 14px;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background: var(--color-white-grey);
      }
    }
  }
}

@use 'sass:map';
@import 'src/styles/variables';
@import 'src/styles/media';

$columns: 12 !default;
$gap: 20px !default;
$breakpoints: (
        xs: (
                'screen': 576px,
                'container': 520px,
        ),
        sm: (
                'screen': 768px,
                'container': 720px,
        ),
        md: (
                'screen': 992px,
                'container': 920px,
        ),
        lg: (
                'screen': 1200px,
                'container': 1120px,
        ),
        xl: (
                'screen': 1400px,
                'container': 1300px,
        ),
        xxl: (
                'screen': 1600px,
                'container': 1440px,
        ),
) !default;

@mixin create-flex-cols($breakpoint: null) {
  $infix: if($breakpoint == null, '', '-#{$breakpoint}');

  @for $i from 1 through $columns {
    .col#{$infix}-#{$i} {
      flex: 0 0 calc(100% / #{$columns} * #{$i});
      max-width: calc(100% / #{$columns} * #{$i});
    }
    .offset#{$infix}-#{$i} {
      margin-left: calc(100% / #{$columns} * #{$i});
    }
    .order#{$infix}-#{$i} {
      order: $i;
    }
  }
}

@each $breakpoint, $width in $breakpoints {
  @media (min-width: map.get($width, 'screen')) {
    .container {
      max-width: map.get($width, 'container') + ($gap * 2);
      //@if ($breakpoint == 'sm') {
      //  padding-left: $gap;
      //  padding-right: $gap;
      //}
    }
  }
}

.container,
.container-fluid {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: $gap;
  padding-right: $gap;
}

.container-fluid {
  max-width: 100%;
}

.align-items {
  &-start {
    align-items: flex-start;
  }

  &-center {
    align-items: center;
  }

  &-end {
    align-items: flex-end;
  }
}

.align-self {
  &-start {
    align-self: flex-start;
  }

  &-center {
    align-self: center;
  }

  &-end {
    align-self: flex-end;
  }
}

.justify-content {
  &-start {
    justify-content: flex-start;
  }

  &-center {
    justify-content: center;
  }

  &-end {
    justify-content: flex-end;
  }

  &-space-between {
    justify-content: space-between;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$gap;
  margin-right: -$gap;

  &.row-10 > * {
    margin-bottom: 10px;
  }

  &.row-15 > * {
    margin-bottom: 15px;
  }

  &.row-20 > * {
    margin-bottom: 20px;
  }

  &.row-30 > * {
    margin-bottom: 30px;
  }

  &.row-40 > * {
    margin-bottom: 40px;
  }
}

[class*='col-'] {
  position: relative;
  padding-left: $gap;
  padding-right: $gap;
  width: 100%
}

.offset {
  &-top-4 {
    margin-top: 4px;
  }

  &-top-6 {
    margin-top: 6px;
  }

  &-top-8 {
    margin-top: 8px;
  }

  &-top-10 {
    margin-top: 10px;
  }

  &-top-12 {
    margin-top: 12px;
  }

  &-top-15 {
    margin-top: 15px;
  }

  &-top-16 {
    margin-top: 16px;
  }

  &-top-16 {
    margin-top: 16px;
  }

  &-top-20 {
    margin-top: 20px;
  }

  &-top-24 {
    margin-top: 24px;
  }

  &-top-30 {
    margin-top: 30px;
  }

  &-top-32 {
    margin-top: 32px;
  }

  &-top-40 {
    margin-top: 40px;
  }

  &-top-48 {
    margin-top: 48px;
  }

  &-top-54 {
    margin-top: 54px;
  }

  &-top-70 {
    margin-top: 70px;
  }

  &-top-80 {
    margin-top: 80px;
  }

  &-top-92 {
    margin-top: 92px;
  }
}

@media (min-width: $sm) {
  .offset-sm {
    &-top-20 {
      margin-top: 20px;
    }
  }
}


@each $breakpoint, $width in $breakpoints {
  @media (min-width: map.get($width, 'screen')) {
    @include create-flex-cols($breakpoint);
  }
}

//grid cols

//@mixin create-grid-cols($breakpoint: null) {
//  $infix: if($breakpoint == null, '', '-#{$breakpoint}');
//
//  @for $i from 0 through $columns {
//    @if $i == 0 {
//      .col#{$infix}-offset-#{$i} {
//        grid-column-start: auto;
//      }
//      .row#{$infix}-offset-#{$i} {
//        grid-row-start: auto;
//      }
//      .col#{$infix}-order-#{$i} {
//        order: 0;
//      }
//    } @else {
//      .col#{$infix}-#{$i} {
//        grid-column-end: span $i;
//      }
//      .col#{$infix}-offset-#{$i} {
//        grid-column-start: $i + 1;
//      }
//      .row#{$infix}-#{$i} {
//        grid-row-end: span $i;
//      }
//      .row#{$infix}-offset-#{$i} {
//        grid-row-start: $i + 1;
//      }
//      .col#{$infix}-order-#{$i} {
//        order: $i;
//      }
//    }
//  }
//}

//@include create-grid-cols;
//
//@each $breakpoint, $width in $breakpoints {
//  @media (min-width: map.get($width, 'screen')) {
//    @include create-grid-cols($breakpoint);
//  }
//}

@import "src/styles/media";

.pagination {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__buttons {
    display: flex;
    align-items: center;

    .button {
      padding: 4px;
      font-size: 13px;
      line-height: 16px;
      min-width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      cursor: pointer;
      transition: .3s border-color;
      color: var(--color-dark-grey);

      &.active {
        background: var(--color-middle-grey);
        color: var(--color-white);
      }

      &.disabled {
        cursor: not-allowed;
        color: var(--color-divider-grey);
      }

      &:not(&.active) {
        &:hover {
          background: var(--color-light-grey);
        }
      }
    }

    .arrow {
      color: var(--color-middle-grey);
    }

    .choice-page {
      display: flex;

      .button {
        margin: 0 2px;
      }
    }
  }
}
